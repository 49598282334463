<template>
<div>
  <b-card
    title="基本信息"
  >
      <b-row>
        <b-col cols="6">
          <b-form-group
          label="事件ID"
          label-for="basicInput"
        >
          <b-form-input
            id="basicInput" v-model="row.id"
          />
        </b-form-group>
        <b-form-group
          label="设备ID"
          label-for="basicInput"
        >
          <b-form-input
            id="basicInput" v-model="row.deviceId"
          />
        </b-form-group>
<!--        <b-form-group
          label="关联机构ID"
          label-for="basicInput"
        >
          <b-form-input
            id="basicInput"
          />
        </b-form-group>
        <b-form-group
          label="群组ID"
          label-for="basicInput"
        >
          <b-form-input
            id="basicInput"
          />
        </b-form-group>
        <b-form-group
          label="房间名ID"
          label-for="basicInput"
        >
          <b-form-input
            id="basicInput"
          />
        </b-form-group>-->
        <b-form-group
          label="标签"
          label-for="basicInput"
        >
          <b-form-input
            id="basicInput" v-model="row.tagSnapshot"
          />
        </b-form-group>
        <b-form-group
          label="事件时间"
          label-for="basicInput"
        >
          <b-form-input
            id="basicInput" v-model="row.triggerTime"
          />
        </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
          label="事件名称"
          label-for="basicInput"
        >
          <b-form-input
            id="basicInput"  :value="eventTypeFormat(row.eventType)"
          />
        </b-form-group>
        <b-form-group
          label="产品名称"
          label-for="basicInput"
        >
          <b-form-input
            id="basicInput" v-model="row.productName"
          />
        </b-form-group>
<!--        <b-form-group
          label="关联机构"
          label-for="basicInput"
        >
          <b-form-input
            id="basicInput"
          />
        </b-form-group>
        <b-form-group
          label="群组"
          label-for="basicInput"
        >
          <b-form-input
            id="basicInput"
          />
        </b-form-group>
        <b-form-group
          label="房间名"
          label-for="basicInput"
        >
          <b-form-input
            id="basicInput"
          />
        </b-form-group>-->
        <b-form-group
          label="分组"
          label-for="basicInput"
        >
          <b-form-input
            id="basicInput"  v-model="row.tenantGroupSnapshot"
          />
        </b-form-group>
<!--        <b-form-group
          label="地址"
          label-for="basicInput"
        >
          <b-form-input
            id="basicInput"  v-model="row.addressSnapshot"
          />
        </b-form-group>-->
        </b-col>
      </b-row>
    </b-card>
    <b-card
      title="通知详情"
    >
      <label for="textarea-number">通知账号:</label>
      <b-form-textarea
        id="textarea-number"
        rows="3"
        class="mb-1 mb-xl-0"
        :value="formatterNoticeTarget(row.noticeTargetInfo)"
      />
    </b-card>
    <b-card
      title="处理详情"
    >
        <b-row>
          <b-col cols="6">
            <b-form-group
            label="处理状态"
            label-for="basicInput"
          >
            <b-form-input
              id="basicInput"  :value="eventHandleFormat(row.handleStatus)"
            />
          </b-form-group>
          <b-form-group
            label="处理人员"
            label-for="basicInput"
          >
            <b-form-input
              id="basicInput"   v-model="row.handleUserName"
            />
          </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
            label="处理时间"
            label-for="basicInput"
          >
            <b-form-input
              id="basicInput"   v-model="row.handleTime"
            />
          </b-form-group>
          <b-form-group
            label="处理记录"
            label-for="basicInput"
          >
            <b-form-input
              id="basicInput"   v-model="row.handleNote"
            />
          </b-form-group>
          </b-col>
        </b-row>
    </b-card>
    <div style="text-align: center">
      <b-button
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          to="/event/reportList"
      >
        返回
      </b-button>

      <b-button
          v-ripple.400="'rgba(216, 39, 40, 0.15)'"
          variant="danger"
          v-b-modal.modal-relation
          v-if="row.needHandle"
      >
        解除警报
      </b-button>
    </div>

    <b-modal
        id="modal-relation"
        title="解除警报"
        centered
        cancel-title="取消"
        ok-title="确定"
        scrollable
        @ok="updateByHandleSingleEventRecord"
    >
      <b-form-input
          id="name-input"
          v-model="handleNoteTmp"
          required
          placeholder="请输入情况"
      ></b-form-input>
    </b-modal>

  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BCard,BFormTextarea
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import request from "@/api/request";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormTextarea
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      id: this.$route.query.id,
      row: [],
      eventTypeOptions: [],
      eventHandleStatusOptions:[],
      eventLvValue: 1,
      handleNoteTmp: "",
    }
  },

  created() {
    this.eventTypeOptionsFormat();
    this.eventHandleStatusOptionsFormat();
    this.getEventInfo();
  },
  watch: {
    '$route.query.id': {
      handler(newVal, oldVal) {
        this.id = newVal
        this.getEventInfo()
      },
    },
    deep: true,
  },

  methods:{
    //事件类型format
    eventTypeFormat(value) {
      if (value != undefined) {
        for (let item of this.eventTypeOptions) {
          if (item.value === value) {
            return item.label;
          }
        }
      }
      return value;
    },

    //事件处理状态format
    eventHandleFormat(value) {
      if (value != undefined) {
        for (let item of this.eventHandleStatusOptions) {
          if (item.value === value) {
            return item.label;
          }
        }
      }
      return value;
    },

    formatterNoticeTarget(value){
      if (value == undefined||value==""){
        return value;
      }
      let parse = JSON.parse(value);
      let result = "";
      for(const parseElement in parse) {
        let key = this.transformNoticeTarget(parseElement)
        let value = parse[parseElement];
        result = result+key+"："+value+"\n";
      }
      return result;
    },

    transformNoticeTarget(parseElement){
      if (parseElement === 'SMS'){
        return "短信";
      }
      if (parseElement === 'WX'){
        return "微信"
      }
      if (parseElement === 'CALL'){
        return "电话"
      }
      return parseElement;
    },

    getEventInfo() {
      request.get('tob/bEventRecord/getEventRecordInfo', {
        id: this.id,
      })
        .then(res => {
          if (res.data.success) {
            this.row = res.data.data
            console.log(this.row)
          } else {
            that.$refs.loginForm.setErrors(res.data.msg)
          }
        })
    },

    updateByHandleSingleEventRecord(modal){
      modal.preventDefault(); //禁止关闭

      request.post('tob/bEventRecord/updateByHandleSingleEventRecord', {
        eventRecordId: this.id,
        handleNote: this.handleNoteTmp,
      })
        .then(res => {
          if (res.data.success) {
            this.$message.success('解除成功')

            this.$nextTick(() => { //关闭
              this.$bvModal.hide('modal-relation')
            })

            this.getEventInfo()
            this.handleNoteTmp="";
          }
        })
    },

    //格式化后端返回的事件类型枚举
    eventTypeOptionsFormat() {
      request.get('tob/bEventRecord/getEnumsByEventLv', {
        eventLvValue: this.eventLvValue,
      }).then(res => {
        if (res.data.success) {
          var enums = res.data.data;
          this.eventTypeOptions = [];
          for (const key in enums) {
            var value = enums[key];
            var enumObject = {
              value: key - 0,
              label: value,
            }

            this.eventTypeOptions.push(enumObject);
          }
        }
      })
    },

    //格式化后端返回的事件处理状态枚举
    eventHandleStatusOptionsFormat() {
      request.get('tob/bEventRecord/getEventHandleStatusEnum', {
      }).then(res => {
        if (res.data.success) {
          var enums = res.data.data;
          this.eventHandleStatusOptions = [];
          for (const key in enums) {
            var value = enums[key];
            var enumObject = {
              value: key - 0,
              label: value,
            }

            this.eventHandleStatusOptions.push(enumObject);
          }
        }
      })
    },

  },
}
</script>
